<template>
  <div id="reserve" class="template">
    <div id="left" class="wrapper">
      <div class="subtitle">
        {{ $t("contact.subtitle") }}
      </div>
      <div class="title">{{ $t("contact.title") }}.</div>
      <div id="left-btn-wrapper">
        <button>
          <a id="call-us" class="text-muted" href="tel:003234581431"
            >+32 3 458 14 31</a
          >
        </button>
        <button>
          <a
            href="mailto:hongfukontich@outlook.com?subject=Reservatie+voor+Hong+Fu+Kontich"
            >hongfukontich@outlook.com</a
          >
        </button>
      </div>
      <div id="extrainfo">
        {{ $t("contact.facebook") }}
        <br />
        {{ $t("contact.question") }}
        <router-link to="/faq"
          ><u>{{ $t("contact.faq") }}</u></router-link
        >.
      </div>
    </div>
    <div id="right" class="wrapper">
      <div class="content float-right">
        <div id="address" class="margin-bottom-25">
          Molenstraat 7, 2550 Kontich
        </div>
        <div id="openinghours" class="margin-bottom-25">
          <div>{{ $t("contact.days1") }}: 12:00-14:00, 17:00-21:45</div>
          <div>
            <b>{{ $t("contact.days2") }}</b>
          </div>
          <div>{{ $t("contact.days3") }}: 12:00-21:45</div>
        </div>
        <div id="extra" class="margin-bottom-25">
          <a href="https://goo.gl/maps/UBny1eEvcQvCGmyCA" target="_blank">{{
            $t("contact.route")
          }}</a>
          <a
            href="https://www.google.com/search?rlz=1C1CHBD_en-GBBE848BE848&ei=W-fnXePgKsGWkwWw9begAw&q=hong+fu+kontich&oq=hong+fu+kontich&gs_l=psy-ab.3...3959.11696..11893...6.4..1.193.1608.29j1......0....1..gws-wiz.......0i71j0i67j0i131i67j0j0i10j0i131j0i273j0i13j0i22i30.tX1tuO1g_SM&ved=0ahUKEwij-vuPvZzmAhVBy6QKHbD6DTQQ4dUDCAs&uact=5#lrd=0x47c3f07d094a2673:0xe5369be99d64fa32,1,,,"
            target="_blank"
            >{{ $t("contact.reviews") }}</a
          >
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reserve",
};
</script>

<style scoped>
#reserve {
  color: #fff;
  text-align: left;
  background: linear-gradient(rgba(41, 31, 37, 0.85), rgba(41, 31, 37, 0.85)),
    url("../assets/pictures/six.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 65px;
  padding-right: 65px;
}
.wrapper {
  width: 50%;
}

#right .content {
  font-size: 18px;
}
#left .title {
  margin-bottom: 25px;
}
#left #left-btn-wrapper button {
  margin-bottom: 5px;
}
#openinghours div {
  margin-bottom: 5px;
}
#extrainfo {
  margin-top: 40px;
  font-family: "SourceSansPro Extra Light";
  font-size: 14px;
}
#extra a {
  font-family: "SourceSansPro Extra Light";
  text-decoration: underline;
  color: #fff;
  margin-right: 15px;
}
#extra a:hover {
  color: #f3efe5;
}

button {
  border: 2px dotted #fff;
  font-size: 16px;
  padding: 15px;
  margin-right: 15px;
  font-weight: bold;
}
button:hover {
  text-decoration: underline;
}
a {
  color: #fff;
  text-decoration: unset;
}

@media only screen and (max-width: 600px) {
  #reserve {
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  #left {
    margin-bottom: 75px;
  }
  #left #left-btn-wrapper {
    display: flex;
    flex-direction: column;
  }
  #left #left-btn-wrapper button {
    margin-right: unset;
  }
  #left #left-btn-wrapper button:first-child {
    margin-bottom: 15px;
  }
  #extrainfo {
    font-size: 12px;
    margin-top: 25px;
  }

  .wrapper {
    width: 100%;
  }
  .title {
    font-size: 30px;
  }
  .subtitle {
    font-size: 19px;
    margin-bottom: 5px;
  }
}
</style>
